@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

$body-bg: #f1f0ec;
$body-color: #050516;

$theme-colors: (
	'site-primary': #03223f,
	'site-orange': #fe5129,
	'site-info': #48cae4,
	'site-heading': #03223f,
	'site-secondary': #f1f0ec,
	'site-light-heading': #f1f0ec,
	'site-main-color': #d6d5d2,
);

@import 'node_modules/bootstrap/scss/bootstrap';

.extra_padding {
	padding: 0 5rem;
	@media (max-width: 1200px) {
		padding: 0 2rem;
	}
	@media (max-width: 991.98px) {
		padding: 0 1rem;
	}
}
.hero_section {
	background-image: url('../img/hero_bg_image.png');
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	background-position: top center;
	position: static;
	// background-position: center;
	overflow: auto;
	&_content {
		margin-top: auto;
		img {
			margin-top: -95px;
			width: 130px;
		}
		h5 {
			font-size: 32px;
			text-align: justify;
			@media (max-width: 767.98px) {
				text-align: left;
			}
		}
		p {
			font-size: 14px;
		}
	}
	&_form {
		height: auto;
		// img {
		// 	width: 40%;
		// }
		p {
			font-weight: bold;
			font-size: 24px;
			margin: 0;
			small {
				font-size: 10px;
			}
		}
		h5 {
			font-size: 27px;
		}
		h6 {
			font-size: 24px;
		}
		&_card {
			min-height: 465px !important;
			@media (max-width: 767.98px) {
				min-height: 600px !important;
			}
			.hand {
				width: 60px;
			}
			label {
				font-size: 12px !important;
			}
			p {
				font-weight: bold;
				font-size: 24px;
				margin: 0;
				small {
					font-size: 10px;
				}
			}
			h5 {
				font-size: 27px;
			}
			.card {
				min-height: 465px !important;
				@media (max-width: 767.98px) {
					min-height: 700px !important;
				}
			}

			.footer {
				position: absolute;
				bottom: 0 !important;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
			}
			.tab {
				font-size: 16px;
				background: #eceaea !important;
				padding: 15px;
				border-radius: 9px !important;
				cursor: pointer;
				height: 100% !important;
			}
			.active-tab {
				font-size: 16px;
				cursor: pointer;
				background: #d6d5d2 !important;
				padding: 15px;
				height: 100% !important;
				border-radius: 9px !important;
			}
			.thankyou p {
				font-size: 20px !important;
			}
		}
	}
}

//bottombar

.bottombar {
	h6 {
		font-size: 16px;
	}
}

//testimonial

.testimonial {
	&_heading {
		font-size: 22px;
		font-weight: bold;
	}
	&_title {
		font-size: 16px;
	}
	&_text {
		font-size: 14px;
	}
}

//RenovationQuotes
.RQuotes {
	text-align: center;
	&_heading {
		font-size: 25px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	hr {
		width: 10%;
		border-top: 5px solid theme-color(site-orange);
		margin-top: 0.5rem;
	}
	&_title {
		font-size: 22px;
		font-weight: 500;
	}
	&_text {
		font-size: 15px;
	}
}

//StairRenovation
.StairR {
	&_heading {
		font-size: 25px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 0;
		text-align: right;
		@media (max-width: 767.98px) {
			text-align: center;
		}
	}
	hr {
		width: 20%;
		border-top: 5px solid theme-color(site-orange);
		margin-top: 0.5rem;
		margin-left: auto;
		margin-right: 0;
		@media (max-width: 767.98px) {
			margin-right: auto;
		}
	}
	&_text {
		font-size: 15px;
		text-align: justify;
	}
	&_table {
		background-color: #d6d5d2;
		.border-bottom {
			border-bottom: 1.5px solid black !important;
		}
		td {
			vertical-align: middle !important;
		}
		&_img {
			width: 60px;
			@media (max-width: 499.98px) {
				width: 40px;
			}
		}
	}
}
.pointer-cursor {
	cursor: pointer !important;
}
html {
	scroll-behavior: smooth;
}
